import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"

import HeaderContext from "../context/header-context"
import MotionWrapper from "../components/motion-wrapper"
import Container from "../components/container"
import Slab from "../components/slab"
import LinkList from "../components/link-list"
import SEO from "../components/seo"

const SpecialismsPage = ({ data }) => {
  const { entries } = data.craft

  const { updateHeaderState } = useContext(HeaderContext)

  useEffect(() => {
    updateHeaderState({
      section: "Specialisms",
      inverseHeaderColor: true,
    })
  }, [updateHeaderState])

  return (
    <>
      <SEO
        description="The services that Mutual specialises in"
        title="Specialisms"
      />
      <MotionWrapper>
        <Slab first bg="muted" color="text">
          <Container>
            <LinkList links={entries} parentPrefix="/does" />
          </Container>
        </Slab>
      </MotionWrapper>
    </>
  )
}

export const SpecialismsIndexQuery = graphql`
  {
    craft {
      entries: entries(section: "specialisms") {
        title
        slug
      }
    }
  }
`

export default SpecialismsPage
